import { useEffect } from "react"
import styles from './Map.module.css'
import Draggable from "react-draggable"
import { useState } from "react";
import FloorOne from "./floors/FloorOne";
import FloorTwo from "./floors/FloorTwo";
import FloorThree from "./floors/FloorThree";
import FloorFour from "./floors/FloorFour";

const apiUrl = `https://kartat-api.otawilma.fi/api`;

const ignoreList = [
    '2406',
    '2148',
    '2164',
    '2146',
    '2159',
    '2162',
    '2167',
    '2174',
    '2175',
    '2177',
    '2168',
    '2171',
    '2173',
    '2410',
    '2129',
    '3238'
]

export default function Map() {
    const [zoom, setZoom] = useState(0.5);
    const [selected, setSelected] = useState(null);
    const [floor, setFloor] = useState(1);

    const initialState = {
        rooms: {},
        loaded: [],
        problems: [],
        activeProblems: [],
    }

    const [state, setState] = useState(initialState);

    const initialize = () => {
        console.log("Hello World!");
        loadCurrentStatus()
	}

    const handleScroll = (e) => {
		const { deltaY } = e;
		const delta = deltaY / 1000;

		const value = Math.min(Math.max(zoom - delta, 0.2), 1.7);
		setZoom(value);
	}

    const loadCurrentStatus = async () => {
        const problems = await loadProblems();
        const alerts = await loadAlerts();

        setState({
            ...state,
            problems,
            activeProblems: alerts
        })
    }

    const loadProblems = () => {
        return new Promise((resolve, reject) => {
            const url = `${apiUrl}/problems`;
            fetch(url)
            .then(async res => {
                if (res.status != 200) throw res
    
                const list = await res.json();
                const idList = list.map(room => room.roomNumber);
                return resolve(idList);
            })
            .catch(err => {
                throw err;
            })
        })
    }

    const loadAlerts = () => {
        return new Promise((resolve, reject) => {
            const url = `${apiUrl}/alerts`;
            fetch(url)
            .then(async res => {
                if (res.status != 200) throw res
    
                const list = await res.json();
    
                return resolve(list)
            })
            .catch(err => {
                throw err;
            })
        });
    }


    const handleLoad = (code) => {
        loadRoom(code);
		setSelected(code);
	}

    const loadRoom = (code) => {
        const url = `${apiUrl}/rooms/${code}?dummy=${Math.random()}`;
        
        fetch(url)
        .then(async res => {
            if (res.status != 200) throw res

            const room = await res.json();
            const problem = room.roomProblems.length > 0;

            const alert = room.roomState == 'alert' | room.roomState == 'waiting';

            setState({ ...state,
                loaded: state.loaded.includes(`${room.roomNumber}`) ? state.loaded : [...state.loaded, `${room.roomNumber}`],
                problems: problem ? [...state.problems, `${room.roomNumber}`] : state.problems,
                activeProblems: alert ? [...state.activeProblems, `${room.roomNumber}`] : state.activeProblems,
                rooms: {...state.rooms, [room.roomNumber]: room }
            });

        })
        .catch(err => {
            throw err;
        })
    }

    useEffect(initialize, []);

    return (
        <div className={styles['content']}>
            <div className={styles['overlay']}></div>
            <div className={styles['side-selector']}>
                <div className={styles['floor-list']}>
					<h5 onClick={() => setFloor(1)} className={floor === 1 ? styles['selected'] : null}>1</h5>
					<h5 onClick={() => setFloor(2)} className={floor === 2 ? styles['selected'] : null}>2</h5>
					<h5 onClick={() => setFloor(3)} className={floor === 3 ? styles['selected'] : null}>3</h5>
					<h5 onClick={() => setFloor(4)} className={floor === 4 ? styles['selected'] : null}>4</h5>
				</div>
            </div>
            <div 
                className={styles['map']}
				onWheel={handleScroll}
                onDoubleClick={() => { if (selected != null) setSelected(null) }}
				style={{
					backgroundSize: `${50 * zoom}px ${50 * zoom}px`,
					width: '100%'
				}}>
                <Draggable>
                    <div className={styles['map-object']}>
                        {
                            floor == 1 ?
                                <FloorOne zoom={zoom} onLoad={handleLoad} problems={state.problems} active={state.activeProblems} />
                            : floor == 2 ?
                                <FloorTwo zoom={zoom} onLoad={handleLoad} problems={state.problems} active={state.activeProblems} />
                            : floor == 3 ?
                                <FloorThree zoom={zoom} onLoad={handleLoad} problems={state.problems} active={state.activeProblems} />
                            :
                            <FloorFour zoom={zoom} onLoad={handleLoad} problems={state.problems} active={state.activeProblems} />
                        }
                    </div>
                </Draggable>
            </div>
            {
                selected ? 
                <RoomInfo 
                    state={state}
                    setState={setState}
                    selected={selected}
                    problem={state.problems.includes(selected)}
                    active={state.activeProblems.includes(selected)}
                    loadRoom={loadRoom}
                    close={() => setSelected(null)}
                    />
                :
                null
            }
        </div>
    )
}

const RoomInfo = ({state, setState, selected, problem, active, loadRoom, close}) => {
    const [room, setRoom] = useState(state.rooms[selected]);
    const [info, setInfo] = useState(room ? room.roomInfo : '');
    const [problems, setProblems] = useState(room ? room.roomproblems : '');
    const [waitingTime, setWaitingTime] = useState(0);

    const headers = new Headers();
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');


    useEffect(() => {

        const interval = setInterval(() => {
            loadRoom(selected)
            const r = state.rooms[selected];
            setRoom(r);

            if (active) {
                if (room) {
                    setWaitingTime(((new Date()).getTime() - room.lastAlert) / 1000);
                }
            }
        }, 1000)

        return () => clearInterval(interval);
    }, [state])

    const initialize = () => {
        const r = state.rooms[selected];

        if (!r) return;
        setRoom(r);
        setInfo(r.roomInfo);
        setProblems(r.roomProblems);

        if (r.roomState == 'alert') {
            setWaitingTime(((new Date()).getTime() - r.lastAlert) / 1000);
        }
    }
    

    useEffect(initialize, [selected, state.loaded]);

    if (!room) return <div className={styles['room-info']}><h2>Loading...</h2></div>

    const saveRoomInfo = () => {
        if (!room) return;
        const url = `${apiUrl}/rooms/${room.roomNumber}/update`;

        fetch(url, { 
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                info: info,
                problems: problems
            })
        })
        .then(res => {
            if (res.status != 200) throw res;

            const currentRoom = room;
            currentRoom.roomInfo = info;
            currentRoom.roomProblems = problems;

            setState({
                ...state,
                rooms: {...state.rooms, [room.roomNumber]: currentRoom },
                problems: problems.length > 0 ? [...state.problems, room.roomNumber] : state.problems.filter(id => id != room.roomNumber),
            })
            
            console.log("done!");
        });
    }

    const onAlert = () => {
        if (!room) return;
        const url = `${apiUrl}/rooms/${room.roomNumber}/alert`;
        fetch(url, { 
            method: 'POST',
        })
        .then(res => {
            if (res.status != 200) throw res;

            setState({
                ...state,
                activeProblems: [...state.activeProblems, room.roomNumber],
                rooms: {...state.rooms, [room.roomNumber]: {...room, roomState: 'alert', lastAlert: (new Date()).getTime()}}
            });

            setRoom(state.rooms[selected]);
            
            console.log("alerting!");
        });
    }

    const waitingTimeLabel = () => {
        const seconds = waitingTime;
        const minutes = Math.floor(seconds / 60);
        const trueSeconds = (seconds % 60).toFixed(0);

        const label = `${minutes}min ${trueSeconds}s`
        return <h2>{ label }</h2>
    }

    const color = active ? 
        'var(--error)'
        : problem ? 
        'var(--warning)'
        :
        'var(--accent-main)';

    const imgUrl = `https://wilma.otawilma.fi/public/rooms/${selected}.jpg`;

    return (
        <div className={styles['room-info']} style={{
            borderLeft: `solid 3px ${color}`
        }}>
            <button className={styles['close']} onClick={close}>X</button>
            <div className={styles['image']} style={{ background: `linear-gradient(to bottom, transparent, var(--map-background)), url(${imgUrl})` }} />
            <h1 style={{ color }}>{selected}</h1>
            <div className={styles['info']}>
            {
                active ?
                <h2>Tilaan kaivataan välitöntä teknistä apua</h2>
                : problem ?
                <h2>Tilasta on raportoitu teknisiä ongelmia</h2>
                :
                <h2>Tilasta ei ole raportoitu ongelmia</h2>
            }
            <p>{ `Viimeisin häiriöilmoitus ${new Date(room.lastEdited).toLocaleDateString('FI-fi', {
                'weekday': 'short',
                'day': 'numeric',
                'month': 'numeric',
                'year': 'numeric',
                'hour': '2-digit',
                'minute': '2-digit',
                'second': '2-digit'
                })}` }</p>
            </div>
            {
                !ignoreList.includes(selected) ?
                <>
                    <h2 className={styles["editor-label"]}>Huoneinfo</h2>
                    <p>Yleistä mainittavaa luokkatilasta ja sen elektroniikasta</p>
                    <div className={styles['editor']}>
                        <textarea className={styles['info-editor']} value={info} onChange={e => setInfo(e.currentTarget.value)} placeholder="Esim. Näytön 1 laitekoodi: W123W567" />
                    </div>
                    <h2 className={styles["editor-label"]}>Raportoi ongelmista</h2>
                    <p>Tänne voi kirjailla pienistäkin luokkatilassa häiritsevistä asioista</p>
                    <div className={styles['editor']}>
                        <textarea className={styles['problems-editor']} value={problems} onChange={e => setProblems(e.currentTarget.value)} placeholder="Esim. HDMI-1 portti ei toimi" />
                    </div>
                    <div className={styles["editor-actions"]}>
                        <button onClick={saveRoomInfo} style={{ backgroundColor: color }}>Tallenna</button>
                    </div>
                </>
                :
                <>
                </>
            }
            {
                room.roomState == 'idle' ?
                    <div className={styles["help-btn"]}>
                        <label>Tarvitseko kiirellistä apua?</label>
                        <label>HUOM. lähettää siviilipalvelushenkilön paikalle</label>
                        <button onClick={onAlert}>APUA-nappi</button>
                    </div>
                : room.roomState == 'alert' ?
                    <div className={styles['alert-container']}>
                        <label>Odotetaan vastauta sivareilta...</label>
                        { waitingTimeLabel() }
                    </div>
                :
                    <div className={styles['alert-container']}>
                        <label>Sivari on matkalla</label>
                        <p>Pieni hetki, varsinkin jos luokka on neloskerroksessa :)</p>
                    </div>

            }
        </div>
    )
}

export const WarningLabel = ({x, y, active}) => {
    return (
        <svg
            x={x}
            y={y}
            transform="translate(2228.11 705.763) scale(.50244)"
        >
            <path 
                transform="scale(3)"
                d="M12 16.99V17M12 7V14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke={ active ? "var(--error)" : 'var(--warning'}
                fill="transparent"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const WarningLabelFloor2 = ({x, y, active}) => {
    return (
        <svg
            x={x}
            y={y}
            transform="translate(477.389 443.316) scale(1.08329)"
        >
            <path 
                transform="scale(1.5)"
                d="M12 16.99V17M12 7V14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke={ active ? "var(--error)" : 'var(--warning'}
                fill="transparent"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const WarningLabelFloor3 = ({x, y, active}) => {
    return (
        <svg
            x={x}
            y={y}
            transform="translate(504.217 717.561) scale(.77083)"
        >
            <path 
                transform="scale(2.5)"
                d="M12 16.99V17M12 7V14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke={ active ? "var(--error)" : 'var(--warning'}
                fill="transparent"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}


export const WarningLabelFloor4 = ({x, y, active}) => {
    return (
        <svg
            x={x}
            y={y}
            transform="matrix(.33724 0 0 .33724 1099.57 748.281)"
        >
            <path 
                transform="scale(5.5)"
                d="M12 16.99V17M12 7V14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke={ active ? "var(--error)" : 'var(--warning'}
                fill="transparent"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}