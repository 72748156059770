import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Map from './routes/Map';
import reportWebVitals from './reportWebVitals';

import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Map />,
	},
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(
	<div className='container'>
		<RouterProvider router={router} />
	</div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
